<script>
  import Tailwindcss from './Tailwindcss.svelte';
  import Input from './Input.svelte';
  import Button from './Button.svelte';
  
  export let step = undefined;
  export let loading;
  export let onInput;
  export let onSubmit;
</script>

<Tailwindcss />

<div class="md:mx-16">
  {#if step}
    <Input id={step.title} label="Describe tu problema" onInput={input => onInput({ id: "selection", content: input, type: step.title })} />
  {/if}
  <h2 class="text-2xl text-center mt-4">Información de contacto</h2>
  <Input id="name" label="Nombre" onInput={input => onInput({ id: "name", content: input })} />
  <Input id="phone" type="phone" label="Teléfono" onInput={input => onInput({ id: "phone", content: input })} />
  <Input id="email" type="email" label="Email" onInput={input => onInput({ id: "email", content: input })} />

  <div class="mt-4">
    <Button title="Enviar" on:click={onSubmit} loading={loading} />
    <p class="mt-2 font-thin text-center">Te contactaremos sobre tu problema sin <span class="font-semibold">ningún</span> tipo de compromiso</p>
  </div>
</div>
