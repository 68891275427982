<script>
  import Tailwindcss from './Tailwindcss.svelte';
  import Button from './Button.svelte';
  import InfoForm from './InfoForm.svelte';
  
  export let step;
  export let onNav;
  export let onInput;
  export let onSubmit;
  export let loading;
</script>

<Tailwindcss />

<div>
  {#if step.title}
    <h2 class="text-2xl text-center font-thin">Has seleccionado <span class="font-semibold">{step.title.toLowerCase()}</span></h2>
  {/if}
  {#if step.type === "selector"}
    {#each step.options as step}
      <div class="mt-2 md:mx-16">
        <Button title={step.title} on:click={() => onNav(step.options)} loading={false} />
      </div>
    {/each}
  {:else if step.type === "input"}
    <InfoForm step={step} onInput={onInput} onSubmit={onSubmit} loading={loading} />
  {:else}
    <InfoForm onInput={onInput} onSubmit={onSubmit} loading={loading} />
  {/if}
</div>
