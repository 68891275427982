export default {
  title: "",
  initial: true,
  type: "selector",
  options: [
    {
      title: "Desatascos",
      options: {
        title: "Desatascos",
        type: "input",
      },
    },
    {
      title: "Fontanería",
      options: {
        title: "Fontanería",
        type: "selector",
        options: [
          {
            title: "Localización de fugas",
            options: {
              title: "Localización de fugas",
              type: "info",
            },
          },
          {
            title: "Reparación de grifos",
            options: {
              title: "Reparación de grifos",
              type: "info",
            },
          },
          {
            title: "Reparación de WC",
            options: {
              title: "Reparación de WC",
              type: "info",
            },
          },
          {
            title: "Reparación de calentador",
            options: {
              title: "Reparación de calentador",
              type: "info",
            },
          },
          {
            title: "Averías generales",
            options: {
              title: "Averías generales",
              type: "info",
            },
          },
        ],
      },
    },
    {
      title: "Albañilería",
      options: {
        title: "Albañilería",
        type: "selector",
        options: [
          {
            title: "Alicatado de viviendas",
            options: {
              title: "Alicatado de viviendas",
              type: "info",
            },
          },
          {
            title: "Solado de viviendas",
            options: {
              title: "Solado de viviendas",
              type: "info",
            },
          },
          {
            title: "Cambio de bañera por plato de ducha",
            options: {
              title: "Cambio de bañera por plato de ducha",
              type: "info",
            },
          },
          {
            title: "Levantamiento de tabiques",
            options: {
              title: "Levantamiento de tabiques",
              type: "info",
            },
          },
          {
            title: "Reformas integrales",
            options: {
              title: "Reformas integrales",
              type: "info",
            },
          },
          {
            title: "Impermeabilizaciones de terraza y tejado",
            options: {
              title: "Impermeabilizaciones de terraza y tejado",
              type: "info",
            },
          },
          {
            title: "Reformas de cocina",
            options: {
              title: "Reformas de cocina",
              type: "info",
            },
          },
          {
            title: "Otros pequeños trabajos",
            options: {
              title: "Otros pequeños trabajos",
              type: "info",
            },
          },
        ],
      },
    },
    {
      title: "Electricidad",
      options: {
        type: "input",
        title: "Electricidad",
      },
    },
    {
      title: "Pintura",
      options: {
        type: "input",
        title: "Pintura",
      },
    },
    {
      title: "Otro",
      options: {
        type: "input",
        title: "Otro",
      },
    },
  ],
};
