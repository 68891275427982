<script>
  import emailjs from "emailjs-com";
  import Tailwindcss from "./Tailwindcss.svelte";
  import FormSteps from "./form-steps.js";
  import Photo from "./Photo.svelte";
  import Form from "./Form.svelte";

  let loading = false;
  let showBackButton = false;
  let step = FormSteps;

  let input = [];

  function onInput(content) {
    const existingIndex = input.findIndex((input) => input.id === content.id);
    if (existingIndex > -1) {
      if (!content.content || content.content.length <= 0) {
        input.splice(existingIndex, 1);
      } else {
        input[existingIndex] = content;
      }
    } else {
      input.push(content);
    }
  }

  function handleNav(updatedStep) {
    step = updatedStep;
    showBackButton = !step.initial;

    if (!step.initial) {
      input = [];
    }

    if (step.type === "info") {
      onInput({ id: "selection", content: "", type: step.title });
    }
  }

  function handleBack() {
    handleNav(FormSteps);
  }

  function showError() {
    alert("Por favor, rellena todos los campos");
  }

  function getField(content, id) {
    return content.find((item) => item.id === id);
  }

  function toEmail(formContent) {
    return {
      name: getField(formContent, "name").content,
      phone: getField(formContent, "phone").content,
      email: getField(formContent, "email").content,
      selection: getField(formContent, "selection").type,
      problemDescription: getField(formContent, "selection").content,
    };
  }

  function sendEmail(emailContent) {
    loading = true;
    const content = toEmail(emailContent);
    emailjs
      .send(
        "service_yehgc8g",
        "template_d7eax3h",
        content,
        "user_48ft05VgLbPGTzkPPu0Jt"
      )
      .then(
        function () {
          alert(
            "El email se ha enviado correctamente. Nos pondremos en contacto contigo próximamente"
          );
          loading = false;
          handleBack();
        },
        function () {
          loading = false;
          alert(
            "Ha habido un problema enviando el email, por favor inténtalo de nuevo en unos minutos"
          );
        }
      );
  }

  function onSubmit() {
    if (!input || input.length === 0) {
      return showError();
    }

    if (input.length < 4) {
      return showError();
    }

    sendEmail(input);
  }
</script>

<Tailwindcss />

<div
  class="flex flex-col px-4 sm:px-6 lg:px-8 max-w-screen-xl mx-auto bg-white overflow-hidden"
>
  <header class="pt-6 divide-y divide-gray-400">
    <nav class="flex justify-center">
      <img
        id="logo"
        alt="Desatascos Badajoz"
        src="logo.png"
        class="h-logoSmall md:h-logoBig w-auto"
      />
    </nav>
  </header>

  <main class="flex flex-col lg:flex-row justify-around mt-8">
    <div>
      <h2 class="text-5xl font-bold text-gray-900">¿Qué necesitas?</h2>
      <p class="text-xl text-gray-700 mt-3">
        Damos servicio en <b>toda</b> la provincia de Badajoz, con asistencia inmediata
      </p>

      {#if showBackButton}
        <div
          class="flex flex-row items-center cursor-pointer text-blue-600 hover:text-blue-500"
          on:click={handleBack}
        >
          <img alt="Atrás" src="back.svg" class="w-8 h-8 mt-6 fill-current" />
          <span class="mt-6 ml-4 cursor-pointer">Atrás</span>
        </div>
      {/if}

      <div class="mt-4">
        <Form {step} onNav={handleNav} {onInput} {onSubmit} {loading} />
      </div>
    </div>

    <Photo currentStep={step} />
  </main>

  <footer class="flex flex-col w-full items-center my-16">
    ¿Prefieres hablarnos directamente?
    <div class="flex md:flex-row flex-col mt-4">
      <a href="tel:+34606558419" class="flex sl:flex-row mr-5">
        <img alt="Teléfono" src="phone.svg" class="h-10 w-10 mr-4" />
        <span class="mt-2">+34 606 558 419</span>
      </a>
      <a href="mailto:desatascosbadajoz@gmail.com" class="flex sl:flex-row">
        <img alt="Mail" src="mail.svg" class="h-10 w-10 mr-4" />
        <span class="mt-2">desatascosbadajoz@gmail.com</span>
      </a>
    </div>
  </footer>
</div>
