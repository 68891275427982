<script>
  import Tailwindcss from './Tailwindcss.svelte';
  import { createEventDispatcher } from 'svelte';
  
  export let title;
  export let loading;

  const dispatch = createEventDispatcher();

  function handleClick() {
    dispatch('click');
  }
</script>

<Tailwindcss />

{#if !loading}
<button class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-blue-700 bg-blue-100 hover:text-blue-600 hover:bg-blue-50 focus:outline-none focus:shadow-outline-blue hover:border-blue-300 transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
on:click={handleClick}
>
  {title}
</button>
{:else}
<button class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-gray bg-gray md:py-4 md:text-lg md:px-10 cursor-not-allowed"
>
  Enviando...
</button>
{/if}
