<script>
  import Tailwindcss from './Tailwindcss.svelte';
  
  export let id;
  export let label;
  export let onInput;
  export let type = "text";
</script>

<Tailwindcss />

<div>
  <label for={id} class="text-1xl text-blue-600 text-left m-2">{label}</label>
  <input id={id} class="border-blue-500 border-solid border-3 px-8 py-3 w-full rounded-md p-2" type={type} on:input={input => onInput(input.target.value)} />
</div>
