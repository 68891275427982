<script>
	import Tailwindcss from './Tailwindcss.svelte';
  
  export let currentStep;
</script>

<Tailwindcss />

<div>
	{#if !!currentStep.initial}
    <div class="flex flex-row mt-16 justify-center lg:flex-col lg:mt-0">
      <img alt="Camión de desatascos" src="truck2.jpeg" class="h-40 object-cover lg:h-48 border-4 border-gray-800 bg-white p-4 transform -rotate-6" />
      <img alt="Camión" src="truck.jpeg" class="h-40 object-cover lg:h-48 border-4 border-gray-800 bg-white p-4 transform rotate-6" />
    </div>
  {:else if currentStep.type === "selector"}
    <img class="w-full" alt="Obrero" src="construction.svg" />
  {:else if currentStep.type === "info" || currentStep.type === "input"}
    <img class="w-full" alt="Personas con el móvil" src="chatting.svg" />
  {/if}
</div>
